import React from "react"
import { createUseStyles } from "react-jss"
import { Content } from "../grid"

const useStyles = createUseStyles({
  container: {
    minHeight: "100vh",
    overflow: "hidden",
    textAlign: "right",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
  },
  "@keyframes tickerWrapper": {
    "0%": {
      transform: "translateY(0%)",
    },
    "100%": {
      transform: "translateY(-1475px)",
    },
  },
  "@keyframes tickerWrapperMobile": {
    "0%": {
      transform: "translateX(0%)",
    },
    "100%": {
      transform: "translateX(-1950px)",
    },
  },
  ticker: {
    position: "relative",
    listStyle: "none",
    animationName: "$tickerWrapper",
    animationDuration: "25s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
    display: "inline-block",
    textAlign: "left",

    "&:hover": {
      animationPlayState: "paused",
    },
  },
  "@media (max-width: 768px)": {
    container: {
      position: "relative",
      minHeight: "auto",
    },
    ticker: {
      width: 6000,
      animationName: "$tickerWrapperMobile",
      display: "block",
      padding: 0,

      "& li": {
        display: "inline-block",
      },
    },
  },
})

function NewsTicker() {
  const cx = useStyles()
  return (
    <div className={cx.container}>
      <ul className={`${cx.ticker} mt-sm mob-mt-none`}>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3"> $100 billion</p>
              <p>estimated loss to ad fraud by 2023.</p>
              <p className="text--color-grey text--strong">Juniper Research</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3"> 36%</p>
              <p>of display ad clicks are fraudulent.</p>
              <p className="text--color-grey text--strong">Lunio</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3"> 50%</p>
              <p>of all ads are never seen by a human.</p>
              <p className="text--color-grey text--strong">Google</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3"> 78%</p>
              <p>of marketers cite click fraud as their top concern.</p>
              <p className="text--color-grey text--strong">Adweek</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">$100 million</p>
              <p>invalid clicks blocked by Lunio.</p>
              <p className="text--color-grey text--strong">Lunio</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3"> 65%</p>
              <p>of buyers click on PPC ads.</p>
              <p className="text--color-grey text--strong">WordLead</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">$100 billion</p>
              <p>estimated loss to ad fraud by 2023.</p>
              <p className="text--color-grey text--strong">Juniper Research</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">36%</p>
              <p>of display ad clicks are fraudulent.</p>
              <p className="text--color-grey text--strong">Lunio</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="h3 text--color-light-purple h3">50%</p>
              <p>of all ads are never seen by a human.</p>
              <p className="text--color-grey text--strong">Google</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">78%</p>
              <p>of marketers cite click fraud as their top concern.</p>
              <p className="text--color-grey text--strong">Adweek</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">65%</p>
              <p>of buyers click on PPC ads.</p>
              <p className="text--color-grey text--strong">WordLead</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">$100 million</p>
              <p>invalid clicks blocked by Lunio.</p>
              <p className="text--color-grey text--strong">Lunio</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">$100 billion</p>
              <p>estimated loss to ad fraud by 2023.</p>
              <p className="text--color-grey text--strong">Juniper Research</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">36%</p>
              <p>of display ad clicks are fraudulent.</p>
              <p className="text--color-grey text--strong">Lunio</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">50%</p>
              <p>of all ads are never seen by a human.</p>
              <p className="text--color-grey text--strong">Google</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">78%</p>
              <p>of marketers cite click fraud as their top concern.</p>
              <p className="text--color-grey text--strong">Adweek</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">65%</p>
              <p>of buyers click on PPC ads.</p>
              <p className="text--color-grey text--strong">WordLead</p>
            </Content>
          </div>
        </li>
        <li>
          <div className="box">
            <Content gap="xs" paddingLeft="none" paddingRight="none">
              <p className="text--color-light-purple h3">$100 million</p>
              <p>invalid clicks blocked by Lunio.</p>
              <p className="text--color-grey text--strong">Lunio</p>
            </Content>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default NewsTicker
