import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  wrapper: (props) => ({
    minHeight: props.minHeight,
    position: 'relative',
    top: props.top,
  }),

  '@media (max-width: 1280px)': {
    wrapper: {
      // Specificity hack to override the prop classes above
      '&&': {
        top: 0,
      },
    },
  },
});

function Wrapper(props) {
  const { children } = props;
  const cx = useStyles({ ...props });

  return (
    <div className={cx.wrapper}>
      {children}
    </div>
  );
}

Wrapper.propTypes = {
  // Disable because its being used by styling which prop-types can't detect
  /* eslint-disable react/no-unused-prop-types */
  /**
   * Minimum height for the wrapper
   */
  minHeight: PropTypes.number,
  /**
   * Top positioning
   */
  top: PropTypes.number,
  /**
   * This element can have children
   */
  children: PropTypes.node,
};

Wrapper.defaultProps = {
  minHeight: 500,
  top: 170,
  children: null,
};

export default Wrapper;
