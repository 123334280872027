import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { createUseStyles } from 'react-jss';
import Card from '../../components/containers/card';
import { Columns, Content, Rows } from '../../components/grid';
import Button from '../../components/interactive/button';
import CtaGroup from '../../components/containers/cta-group';
import Copy from '../../components/content/copy';

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.colors.core.core,
    fontWeight: 'bold',
  },
  subTitle: {
    fontWeight: 'bold',
    opacity: 0.33,
  },
  card: {
    boxShadow: '0 0 25px #00000019',
  },
  image: {
    height: '90px !important',
    width: '90px !important',
    borderRadius: '20px',
  },
}));

function CaseStudyCard(props) {
  const {
    image, title, subTitle, link, text, btnColor, btnColorHover, alt,
  } = props;

  const img = getImage(image.localFile);
  const cx = useStyles();

  const colouredButtons = {
    '--button-color': btnColor,
    '--button-hover-color': btnColorHover,
  };

  if (alt) {
    return (
      <Card className={`card--case-study ${cx.card}`}>
        <Rows>
          <Columns count="1">
            <Content>
              <div className="card--case-study__img">
                <GatsbyImage
                  className={cx.image}
                  image={img}
                  alt={image.alternativeText}
                />
              </div>
              <div>
                <p className={`${cx.title} h5`}>
                  {title}
                </p>
                <p className={`${cx.subTitle} h5`}>
                  {subTitle}
                </p>
              </div>

              <p>
                <Copy contentToParse={text} />
              </p>
            </Content>
          </Columns>
        </Rows>
      </Card>
    );
  }

  return (
    <Card className="card--case-study">
      <Rows>
        <Columns count="1">
          <Content>
            <div className="card--case-study__img">
              <GatsbyImage image={img} alt={image.alternativeText} />
            </div>
            <p className="h4">{title}</p>
            <Copy contentToParse={text} />
            <CtaGroup style={colouredButtons} className="mb-xxxs">
              <Button color="secondary" href={link}>
                View Case Study
              </Button>
            </CtaGroup>
          </Content>
        </Columns>
      </Rows>
    </Card>
  );
}

CaseStudyCard.propTypes = {
  /**
   * Alternate case study styling
   */
  alt: PropTypes.bool,
  /**
   * Subtitle text
   */
  subTitle: PropTypes.string,
};

CaseStudyCard.defaultProps = {
  alt: false,
  subTitle: null,
};

export default CaseStudyCard;
