import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import parse from 'html-react-parser';
import CtaGroup from '../../../components/containers/cta-group';
import Section from '../../../components/containers/section';
import Heading from '../../../components/content/heading';
import Button from '../../../components/interactive/button';

import ctaLeft from '../../../images/content-hub/cta_left.png';
import ctaRight from '../../../images/content-hub/cta_right.png';

const useStyles = createUseStyles({
  container: {
    maxWidth: 590,
    minHeight: 770,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1,

    '& h2': {
      textAlign: 'center',
    },
  },
  button: {
    display: 'inline-block',
  },
  section: {
    backgroundImage: `url("${ctaRight}"), url("${ctaLeft}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '-135px, calc(100% + 30px)',
  },
  '@media (max-width: 1650px)': {
    section: {
      backgroundPosition: '-300px, calc(100% + 180px)',
    },
  },
  '@media (max-width: 1300px)': {
    section: {
      backgroundPosition: '-450px, calc(100% + 350px)',
    },
  },
  '@media (max-width: 900px)': {
    section: {
      backgroundPosition: '-500px, calc(100% + 400px)',
    },
  },
  '@media (max-width: 768px)': {
    section: {
      backgroundImage: 'none',

      '&:before': {
        content: '""',
        backgroundRepeat: 'no-repeat',
        display: 'inline-block',
        backgroundImage: `url("${ctaRight}")`,
        backgroundPosition: '50%',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        opacity: 0.25,
      },
    },
    button: {
      display: 'block',
    },
  },
});

function BottomCTA({ data }) {
  const cx = useStyles();
  const {
    heading, buttonOneURL, buttonOneText, buttonTwoURL, buttonTwoText,
  } = data;

  return (
    <Section className={cx.section}>
      <CtaGroup>
        <div className={cx.container}>
          <Heading level={2} className="mb-lg px-sm mob-px-none">
            {parse(heading)}
          </Heading>

          <div>
            <div className={`${cx.button} tab-mr-md mb-xs`}>
              <Button color="orange" href={buttonOneURL}>
                {buttonOneText}
              </Button>
            </div>

            <Button color="core" href={buttonTwoURL}>
              {buttonTwoText}
            </Button>
          </div>
        </div>
      </CtaGroup>
    </Section>
  );
}

BottomCTA.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
};

export default BottomCTA;
