import React, { useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Section from "../../../components/containers/section"
import Heading from "../../../components/content/heading"
import RunicAccent from "../../../components/accents/runic-accent"
import SectionBackground from "../../../components/containers/section-background"
import SectionContent from "../../../components/containers/section-content"

gsap.registerPlugin(ScrollTrigger)

const useStyles = createUseStyles(() => ({
  block: {
    display: "flex",
    width: "35%",
    margin: "0 auto",
    "margin-bottom": 160,
  },
  "@media (max-width: 960px)": {
    block: {
      width: "80%",
    },
  },
  "@media (max-width: 650px)": {
    block: {
      width: "100%",
      "margin-bottom": 64,
    },
  },
  right: {},
  title: {
    "margin-bottom": 24,
  },
  imageContainer: {
    width: 64,
    "margin-right": 48,
    "margin-top": 6,
    overflow: "hidden",
    "flex-shrink": 0,
  },
}))

function WeHelp(props) {
  const { data } = props
  const cx = useStyles()

  useLayoutEffect(() => {
    data.forEach((id, index) => {
      gsap.from(`#animationid${index}`, {
        opacity: 0,
        scrollTrigger: {
          trigger: `#animationid${index}`,
          start: "bottom bottom",
          // triggers when the bottom of the .card div is at the bottom of the viewport
          toggleActions: "play",
        },
        duration: 1,
        // How long the animation should be
        delay: 0,
        // Delay before starting animation
      })
    })
  }, [])

  return (
    <Section>
      <SectionContent
        width="wide"
        paddingTop="xl"
        paddingBot="xl"
        className="pb-xs mob-pb-none"
      >
        {data.map(({ ...item }, index) => {
          const { heading, content, imageIcon, alternativeText } = item
          const img = getImage(imageIcon.localFile)
          return (
            <div key={heading} className={cx.block} id={`animationid${index}`}>
              <div className={cx.imageContainer}>
                <GatsbyImage image={img} alt={alternativeText} />
              </div>

              <div className={cx.right}>
                <Heading className={cx.title} level={2}>
                  {parse(heading)}
                </Heading>
                <p>{content}</p>
              </div>
            </div>
          )
        })}
      </SectionContent>
    </Section>
  )
}

WeHelp.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default WeHelp
