import React from "react"
import Section from "@components/containers/section"
import Heading from "@components/content/heading"
import SectionContent from "@components/containers/section-content"
import { Columns, Rows } from "@components/grid"
import { createUseStyles } from "react-jss"
import {
  faInfinity,
  faMousePointer,
  faTimesCircle,
  faFilter,
  faMagnet,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import Flywheel from "./Flywheel"
import lowQualityClicks from "../../../images/content-hub/low_quality_clicks.png"
import misleadingICP from "../../../images/content-hub/misleading_icp.png"
import inaccurateOptimizations from "../../../images/content-hub/inaccurate_optimizations.png"
import attractMoreSame from "../../../images/content-hub/attract_more_same.png"

const useStyles = createUseStyles(theme => ({
  columns: {
    "& p": {
      marginBottom: theme.spacing.md,
    },
  },
  line: {
    height: 1900,
    width: 3,
    backgroundColor: "#EAE7ED",
    position: "absolute",
    top: 0,
    left: "-70px",
  },
  wheel: {
    height: 1500,
    width: 60,
    border: "3px solid #EAE7ED",
    position: "absolute",
    left: "-127px",
    borderRadius: "50px",
  },
  flywheelWrapper: {
    display: "block",
  },
  infinity: {
    position: "absolute",
    bottom: 10,
    left: 10,
    fontSize: 25,
    color: "#EAE7ED",
  },
  arrow1: {
    position: "absolute",
    top: 270,
    right: "-11px",
    color: "#EAE7ED",
  },
  arrow2: {
    extend: "arrow1",
    top: 650,
  },
  arrow3: {
    extend: "arrow2",
    top: 650,
    right: 46,
    transform: "rotate(180deg)",
  },
  arrow4: {
    extend: "arrow1",
    top: 1075,
  },
  "@media (max-width: 768px)": {
    container: {
      maxWidth: "none",
      width: "100%",
      margin: "0",
      marginBottom: 60,
    },
    first: {
      display: "block",

      "& $arrow": {
        display: "none",
      },
    },
    last: {
      display: "block",
      order: 10,

      "& svg": {
        transform: "rotate(90deg) !important",
      },
    },
    flywheelWrapper: {
      display: "flex",
      flexDirection: "column",
      marginRight: `-${theme.spacing.xs}`,
      marginLeft: `-${theme.spacing.xs}`,
    },
  },
}))

function FlywheelOne({ data }) {
  const cx = useStyles()
  const { colOneText, colTwoText, heading, preHeading } = data

  return (
    <Section>
      <SectionContent paddingTop="md" paddingBot="sm">
        <div className={cx.line} />
        <Heading preheading={preHeading} className="mb-md">
          {parse(heading)}
        </Heading>
        <Rows className="mb-lg">
          <Columns count="2" className={cx.columns}>
            <div className="tab-pr-sm">
              <p>{parse(colOneText)}</p>
            </div>
            <div className="tab-pl-sm">
              <p>{parse(colTwoText)}</p>
            </div>
          </Columns>
        </Rows>
        <div className={cx.flywheelWrapper}>
          <div className={cx.wheel}>
            <FontAwesomeIcon
              className={cx.arrow1}
              icon={faCaretDown}
              rotate={180}
              size="2x"
            />
            <FontAwesomeIcon
              className={cx.arrow2}
              icon={faCaretDown}
              rotate={180}
              size="2x"
            />
            <FontAwesomeIcon
              className={cx.arrow3}
              icon={faCaretDown}
              size="2x"
            />
            <FontAwesomeIcon
              className={cx.arrow4}
              icon={faCaretDown}
              rotate={180}
              size="2x"
            />
            <FontAwesomeIcon
              className={cx.infinity}
              icon={faInfinity}
              rotate={180}
            />
          </div>
          <Flywheel
            number={4}
            heading="Attract More Of The Same"
            content="Bidding algorithms are fed inaccurate data leading to inaccurate bids and more bad traffic."
            image={attractMoreSame}
            rightImage
            icon={faMagnet}
            first
          />
          <Flywheel
            number={1}
            heading="Low-Quality Clicks"
            content="Invalid and fraudulent traffic clicks your ads and wastes your marketing budget."
            image={lowQualityClicks}
            icon={faMousePointer}
            className="mt-none tab-mt-xxl"
          />
          <Flywheel
            number={2}
            heading="Misleading ICP"
            content="With bad data in the mix, it becomes difficult to identify the behaviors of your real customers."
            image={misleadingICP}
            rightImage
            icon={faTimesCircle}
          />

          <Flywheel
            number={3}
            heading="Inaccurate Optimisations"
            content="Decisions that affect your whole funnel are made from wrong insights."
            image={inaccurateOptimizations}
            icon={faFilter}
          />
          <Flywheel
            number={4}
            heading="Attract More Of The Same"
            content="Bidding algorithms are fed inaccurate data leading to inaccurate bids and more bad traffic."
            image={attractMoreSame}
            rightImage
            icon={faMagnet}
          />
          <Flywheel
            number={1}
            heading="Low-Quality Clicks"
            content="Invalid and fraudulent traffic clicks your ads and wastes your marketing budget."
            image={lowQualityClicks}
            icon={faMousePointer}
            last
          />
        </div>
      </SectionContent>
    </Section>
  )
}

FlywheelOne.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default FlywheelOne
