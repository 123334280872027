import React from 'react';
import Section from '@components/containers/section';
import SectionContent from '@components/containers/section-content';
import Heading from '@components/content/heading';
import Button from '@components/interactive/button';
import { Columns, Rows } from '@components/grid';
import { createUseStyles } from 'react-jss';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import Pillar from './Pillar';

const useStyles = createUseStyles((theme) => ({
  columns: {
    '& p': {
      marginBottom: theme.spacing.xs,
    },
  },
  preheading: {
    '&&': {
      fontSize: 24,
      color: theme.colors.lights.white,
    },
  },
  section: {
    background: `linear-gradient(218deg, #562C87, ${theme.colors.core.coreDark})`,
  },
  square: {
    height: 1258,
    width: 1258,
    backgroundColor: theme.colors.lights.white,
    opacity: 0.1,
    position: 'absolute',
    right: '-800px',
    top: '-880px',
    transform: 'rotate(65deg)',
  },
  square2: {
    extend: 'square',
    right: '-1300px',
    top: '0',
  },
}));

function AdPlatformsDont({ data }) {
  const cx = useStyles();
  const {
    buttonText, buttonURL, colOneText, colTwoText, heading, preHeading,
  } = data;

  return (
    <Section theme="dark" className={cx.section}>

      <div className={cx.square} />
      <div className={cx.square2} />

      <SectionContent paddingTop="md" paddingBot="sm">

        <Heading preheading={preHeading} className="pb-md" preHeadingClasses={cx.preheading}>
          {parse(heading)}
        </Heading>
        <Rows>
          <Columns count="2" className={cx.columns}>
            <div className="tab-pr-md">
              <p>
                {parse(colOneText)}
              </p>
            </div>
            <div className="tab-pl-sm">
              <p>
                {parse(colTwoText)}
              </p>
            </div>
          </Columns>
        </Rows>

        <Button color="yellow-black" href={buttonURL} className="mt-xxs text--color-dark">
          {buttonText}
        </Button>

        <Pillar.Wrapper>
          <Pillar
            title="Total Spend"
            bottom="$10,000"
            height={427}
            padding="112px 0 65px"
            arrowRight="-20%"
          />

          <Pillar
            title="Cost of Click Fraud"
            bottom="-$2,000"
            height={383}
            padding="70px 0 0"
            robotTop
          />

          <Pillar
            title="Refunded By Ad Platforms"
            bottom={(
              <div>
                =$20
                <div>(1% of click fraud total)</div>
              </div>
            )}
            height={339}
            pillarSide={{ width: 60, top: 16 }}
            robotRight
          />
        </Pillar.Wrapper>
      </SectionContent>
    </Section>
  );
}

AdPlatformsDont.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
};

export default AdPlatformsDont;
