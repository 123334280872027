import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export default class TabSelect extends Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
    this.scrollRef = React.createRef()
    this.tabs = []
    this.state = {
      leftOffset: 0,
      width: 0,
      containerWidth: 1000,
    }
  }

  componentDidMount() {
    this.setInitialSelect()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedIndex !== this.props.selectedIndex) {
      this.changeSelectedButton(this.props.selectedIndex)
    }
  }

  componentWillUnmount() {
    this.clearInitialSelect()
  }

  setInitialSelect = () => {
    if (this.initialSelect) {
      return
    }

    this.initialSelect = setTimeout(() => {
      // allow for no item to be selected ( -1 )
      const { selectedIndex } = this.props
      if (selectedIndex === -1) {
        this.changeSelectedButton(-1)
      } else {
        this.changeSelectedButton(0)
      }
    }, 500)
  }

  clearInitialSelect = () => {
    if (this.initialSelect) {
      clearTimeout(this.initialSelect)
    }
  }

  changeSelectedButton = index => {
    let left = 0
    let width = 0
    if (index !== -1) {
      left = this.tabs[index].offsetLeft
      width = this.tabs[index].offsetWidth
    }
    const right = left + width

    this.scrollRef.current.scrollLeft =
      left - (width - 64) < 0 ? left : left - (width - 64)

    if (index !== this.props.selectedIndex) {
      this.props.onClick(index)
    }

    this.setState({
      containerWidth: this.containerRef.current.offsetWidth - 15,
      leftOffset: left,
      width: right,
    })
  }

  render() {
    const { tabs, selectedIndex } = this.props
    const clipPath = `inset(0.5px ${
      this.state.containerWidth - this.state.width
    }px 0.5px ${this.state.leftOffset + 16}px round 26px)`

    return (
      <nav className="tab-select">
        <div className="tab-select__container">
          <div className="tab-select__overflow">
            <div className="tab-select__track" ref={this.scrollRef}>
              <div className="tab-select__controller" aria-hidden="true">
                {tabs.map((item, index) => {
                  const classes = classNames(
                    "tab-select__button button",
                    selectedIndex === index && "tab-select__button--disabled",
                  )
                  return (
                    <button
                      ref={tab => (this.tabs[index] = tab)}
                      key={index}
                      onClick={() => this.changeSelectedButton(index)}
                      className={classes}
                    >
                      {item}
                    </button>
                  )
                })}
              </div>

              <div
                className="tab-select__items"
                style={{
                  clipPath,
                }}
                ref={this.containerRef}
              >
                <div className="tab-select__controller__buttons">
                  {tabs.map((item, index) => {
                    const classes = classNames(
                      "tab-select__button button tab-select__button--active",
                    )
                    return (
                      <button key={index} className={classes}>
                        {item}
                      </button>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

TabSelect.defaultProps = {
  tabs: [],
  selectedIndex: 0,
}

TabSelect.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
}
