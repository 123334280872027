import React, { useState, useEffect } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-scroll"
import PropTypes from "prop-types"

import Section from "../containers/section"
import TabSelect from "./tab-select"

const useStyles = createUseStyles({
  outerContainer: {
    width: "100%",
    "padding-top": 20,
    "padding-bottom": 20,
  },
  stuck: {
    "background-color": "#FAF9FA",
    position: "fixed",
    top: 92,
    "z-index": 98,
    "box-shadow": "#100b151a 0px 2px 8px 4px",
  },
  "@media (max-width: 1280px)": {
    stuck: {
      top: 92,
    },
  },
  "@media (max-width: 1000px)": {
    outerContainer: {
      visibility: "hidden",
    },
  },
  tabSelectContainer: {
    "background-color": "unset !important",
  },
  hidden: {
    display: "none",
  },
})

function StickyNav(props) {
  const { navigateToElement } = props
  const cx = useStyles()
  const [scroll, setScroll] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(-1)

  const tabsArray = [
    "What's Missing",
    "The Impact",
    "Invalid Clicks",
    "Next Actions",
    "Ad Protection",
  ]

  const anchorScrollLocationNames = [
    "WhatsMissing",
    "AdNetworks",
    "FlywheelOne",
    "FlywheelTwo",
    "YouTubeVid",
  ]

  let nextToElemet = false
  let isScrollingDelay = false

  function activeMenuItem() {
    // Clear our timeout throughout the scroll
    window.clearTimeout(isScrollingDelay)
    // Set a timesout to run after scrolling ends
    isScrollingDelay = setTimeout(() => {
      // Run the callback
      let foundInThisLooop = false
      anchorScrollLocationNames.forEach((item, index) => {
        const element = document.querySelector(`#${item}`)
        if (element || typeof element !== "undefined") {
          if (element.classList.contains("active")) {
            setSelectedTabIndex(index)
            nextToElemet = true
            foundInThisLooop = true
          }
        }
      })
      if (!foundInThisLooop && nextToElemet) {
        setSelectedTabIndex(-1)
        nextToElemet = false
      }
    }, 500)
  }

  // set the menu to stick up against main menu once scroll past it
  useEffect(() => {
    function onScroll() {
      if (typeof window !== "undefined") {
        const menu = document.querySelector("#sticky-menu")
        const menuTop = menu.offsetTop
        const scrollCheck = window.scrollY > menuTop - 92
        activeMenuItem()
        if (scrollCheck !== scroll) {
          setScroll(scrollCheck)
        }
      } else {
        setScroll(true)
      }
    }
    document.addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [scroll, setScroll])

  // when a tab is selected in child component scroll to it
  function tabSelected(item) {
    navigateToElement(anchorScrollLocationNames[item])
    setSelectedTabIndex(item)
  }

  return (
    <div
      className={`${cx.outerContainer} ${scroll ? cx.stuck : ""}`}
      id="sticky-menu"
    >
      <Section
        className={`flex flex--row flex--justify-center ${cx.tabSelectContainer}`}
      >
        <div>
          {anchorScrollLocationNames.map(item => (
            <Link
              activeClass="active"
              offset={-140}
              className={item}
              id={item}
              to={item}
              spy
            />
          ))}
        </div>
        <TabSelect
          onClick={tabSelected}
          tabs={tabsArray}
          selectedIndex={selectedTabIndex}
        />
      </Section>
    </div>
  )
}

StickyNav.propTypes = {
  navigateToElement: PropTypes.func.isRequired,
}

export default StickyNav
