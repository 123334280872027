import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { StaticImage } from 'gatsby-plugin-image';
import { createUseStyles } from 'react-jss';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import NewsTicker from '../../../components/animated/news-ticker';
import Section from '../../../components/containers/section';
import SectionContent from '../../../components/containers/section-content';
import Heading from '../../../components/content/heading';
import { Columns, Content } from '../../../components/grid';

gsap.registerPlugin(ScrollTrigger);

const useStyles = createUseStyles({
  chess: {
    transform: 'translate(-20%,74%)',
    '@media (max-width: 769px)': {
      transform: 'translate(-20%,39%)',
    },
    '@media (max-width: 1280px)': {
      transform: 'translate(0%,74%)',
    },
    '@media (max-width: 768px)': {
      transform: 'translate(0%,58%)',
    },
    '@media (max-width: 640px)': {
      transform: 'translate(0%,39%)',
    },
  },
  // Empty to access the mobile styling
  section: {},
  '@media (max-width: 768px)': {
    section: {
      '&&': {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
  },
});

function LackOfTransparency({ data }) {
  const classes = useStyles();
  const { content, header } = data;

  useEffect(() => {
    gsap.to('#opacity', {
      scrollTrigger: {
        trigger: '#opacity',
        start: 'top center',
        end: 'top top+250px',
        scrub: true,
        toggleActions: 'play none none reverse',
      },
      opacity: 0.3,
    });
  }, []);

  return (
    <Section id="scroll-anchor0">
      <SectionContent paddingTop="md" paddingBot="md" className={classes.section}>
        <Columns count="2">
          <SectionContent paddingTop="xxl" className="tab-pr-none mob-pb-xs tab-pb-xxl" paddingBot="none">
            <Columns count="1">
              <Heading level={2} className="tab-pr-xl pb-xs">
                {parse(header)}
              </Heading>
            </Columns>
            <Columns count="1">
              <Content paddingLeft="none" paddingRight="none">
                <p>
                  {parse(content)}
                </p>
              </Content>
            </Columns>

          </SectionContent>
          <NewsTicker />
        </Columns>
        <div className={`${classes.chess} flex`}>
          <StaticImage src="../../../images/content-hub/rook_black.png" />
          <StaticImage src="../../../images/content-hub/pawn_black.png" />
        </div>
      </SectionContent>
    </Section>
  );
}

LackOfTransparency.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
};

export default LackOfTransparency;
