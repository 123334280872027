import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Button from '@components/interactive/button';
import { createUseStyles } from 'react-jss';

gsap.registerPlugin(ScrollTrigger);

const useStyles = createUseStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing.xxl,
    position: 'relative',
    maxWidth: 566,
    height: '100%',
    opacity: 0,

    '& p': {
      fontSize: '18px',
      lineHeight: '32px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  image: {
    position: 'absolute',
    left: 10,
  },
  button: {
    paddingLeft: 0,
    fontSize: '18px',
    marginTop: 'auto',
  },

  '@media (max-width: 768px)': {
    content: {
      paddingTop: theme.spacing.sm,
      height: 'auto',
    },
    image: {
      position: 'relative',
      left: 0,
    },
    container: {
      paddingTop: theme.spacing.sm,
      paddingLeft: 0,
    },
  },
}));

function CTA(props) {
  const {
    title, text, buttonText, buttonLink, image,
  } = props;
  const cx = useStyles();
  const containerRef = useRef();

  useLayoutEffect(() => {
    gsap.to(containerRef.current, {
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top bottom',
        end: 'top top+250px',
        scrub: true,
        toggleActions: 'play none none reverse',
      },
      opacity: 1,
    });
  }, []);

  return (
    <div className={cx.container} ref={containerRef}>
      <div>
        <img alt="" src={image} className={cx.image} />
      </div>
      <div className={cx.content}>
        <h3 className="h3 mb-sm">
          {title}
        </h3>
        <p className="mb-xs">
          {text}
        </p>
        <Button
          styletype="blank"
          className={cx.button}
          href={buttonLink}
          icon={faCaretRight}
          color="core"
        >
          {buttonText}
        </Button>
      </div>

    </div>
  );
}

CTA.propTypes = {
  /**
   * Header text
   */
  title: PropTypes.string.isRequired,
  /**
   * Content text
   */
  text: PropTypes.string.isRequired,
  /**
   * Text for the button
   */
  buttonText: PropTypes.string.isRequired,
  /**
   * Link for the button
   */
  buttonLink: PropTypes.string.isRequired,
  /**
   * Image to display
   */
  image: PropTypes.string.isRequired,
};

export default CTA;
