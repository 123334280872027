import React from 'react';
import ImageComparisonSlider from 'react-compare-image';
import { createUseStyles } from 'react-jss';
import Handle from './Handle';
import rawTheme from '../../../theme';

const useStyles = createUseStyles((theme) => ({
  content: {
    border: `2px solid ${theme.colors.darks.lightGrey}`,
    boxShadow: `0 3px 6px ${theme.colors.darks.black}1A`,
    borderRadius: 30,
    overflow: 'hidden',
  },
}));

function ImageSlider(props) {
  const cx = useStyles();

  return (
    <div className={cx.content}>
      <ImageComparisonSlider
        sliderLineColor={rawTheme.colors.darks.lightGrey}
        handle={<Handle />}
        {...props}
      />
    </div>
  );
}

export default ImageSlider;
