import React, { useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { createUseStyles } from 'react-jss';
import GoogleCard from '../../images/content-hub/cards/Google-card.png';
import LinkedInCard from '../../images/content-hub/cards/LinkedIn-card.png';
import RedditCard from '../../images/content-hub/cards/Reddit-card.png';
import FacebookCard from '../../images/content-hub/cards/Facebook-card.png';
import TwitterCard from '../../images/content-hub/cards/Twitter-card.png';
import Section from '../containers/section';

gsap.registerPlugin(ScrollTrigger);

const useStyles = createUseStyles((theme) => ({
  card: {
    position: 'absolute',
    boxShadow: '0px 0px 30px #100b151a',
    borderRadius: '15px',
    height: 370,
  },
  cards: {
    paddingTop: '15%',
    height: 600,
    overflow: 'visible',
  },
  '@media (max-width: 768px)': {
    card: {
      maxWidth: 218,
      height: 'auto',
    },
    cards: {
      marginLeft: `-${theme.spacing.md}`,
      marginRight: `-${theme.spacing.md}`,
      overflow: 'visible',
    },
  },
  '@media (max-width: 425px)': {
    card: {
      left: theme.spacing.md,
    },
  },
}));

function DeckCards() {
  const classes = useStyles();

  const ids = ['#google', '#linkedin', '#reddit', '#facebook', '#twitter'];
  let rotation = -20;

  useLayoutEffect(() => {
    ids.forEach((id) => {
      gsap.to(id, {
        rotation,
        transformOrigin: 'bottom left',
        // Rotates from centre by default
        scrollTrigger: {
          trigger: '#twitter',
          start: 'bottom bottom',
          // triggers when the bottom of the .card div is at the bottom of the viewport
          toggleActions: 'play reverse play reverse',
        },
        duration: 2,
        // How long the animation should be
        delay: 0,
        // Delay before starting animation
      });
      rotation += 10;
    });
  }, []);

  return (
    <Section id="cards" className={classes.cards}>
      <img id="google" src={GoogleCard} alt="" className={`${classes.card} hidden--tab-down`} />
      <img id="linkedin" src={LinkedInCard} alt="" className={`${classes.card} hidden--tab-down`} />
      <img id="reddit" src={RedditCard} alt="" className={classes.card} />
      <img id="facebook" src={FacebookCard} alt="" className={classes.card} />
      <img id="twitter" src={TwitterCard} alt="" className={classes.card} />
    </Section>
  );
}

export default DeckCards;
