import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  lineAccent: {
    width: '100%',
    height: 2,
    background: 'transparent linear-gradient(30deg, #05C46B 0%, #00D8D6 38%, #417ED100 80%, #575FCF00 100%) 0% 0% no-repeat padding-box;',
  },
});

function LineAccent() {
  const classes = useStyles();
  return <div className={classes.lineAccent} />;
}

export default LineAccent;
