import React from "react"
import PropTypes from "prop-types"
import Section from "@components/containers/section"
import SectionContent from "@components/containers/section-content"
import Heading from "@components/content/heading"
import { createUseStyles } from "react-jss"
import CssAccentGroup from "@components/accents/css-accent-group"
import rawTheme from "@src/theme"
import parse from "html-react-parser"
import { Rows, Columns } from "../../../components/grid"
import Flywheel from "./Flywheel"
import CTA from "./CTA"

import excludeTraffic from "../../../images/content-hub/exclude_traffic.png"
import attractCustomers from "../../../images/content-hub/attract_customers.png"
import revenue from "../../../images/content-hub/revenue.png"
import feedOptimization from "../../../images/content-hub/feed_optimization.png"
import invalidAudiences from "../../../images/content-hub/icon-invalid-audiences.png"
import ownYourData from "../../../images/content-hub/icon-own-data.png"
import crossPlatformAnalytics from "../../../images/content-hub/icon-cross-platform-analytics.png"
import revenueThroughExclusion from "../../../images/content-hub/icon-revenue-exclusion.png"

const useStyles = createUseStyles(theme => ({
  top: {
    maxWidth: 885,
  },
  // Empty to access the mobile styling
  section: {},
  box4: {
    order: 1,
  },
  box3: {
    order: 2,
  },
  flywheelWrapper: {
    display: "block",
  },
  accent: {
    transform: "translate(100%, 30%)",
  },
  "@media (max-width: 1240px)": {
    flywheelWrapper: {
      textAlign: "center",
    },
    accent: {
      transform: "translate(90%, -20%)",
    },
  },
  "@media (max-width: 768px)": {
    box3: {
      order: 1,
    },
    box4: {
      order: 2,
    },
    flywheelWrapper: {
      display: "flex",
      flexDirection: "column",
      marginRight: `-${theme.spacing.xs}`,
      marginLeft: `-${theme.spacing.xs}`,
    },
  },
}))

function FlywheelTwo(props) {
  const { data } = props
  const cx = useStyles()

  const {
    heading,
    content,
    preHeading,
    iconText,
    iconTextTwo,
    iconTextThree,
    iconTextFour,
  } = data

  const accentData = [
    {
      size: 17,
      color: rawTheme.colors.accents.ragnarok,
      opacity: 1,
      left: 0,
      top: 30,
    },
    {
      size: 14,
      color: rawTheme.colors.accents.ragnarok,
      opacity: 0.3,
      left: 40,
      top: 25,
    },
    {
      size: 12,
      color: rawTheme.colors.accents.ragnarok,
      opacity: 0.6,
      left: 25,
      top: 45,
    },
  ]

  return (
    <Section>
      <SectionContent paddingTop="md" paddingBot="sm">
        <div className={cx.top}>
          <CssAccentGroup
            data={accentData}
            className={`hidden--tab-down ${cx.accent}`}
          />

          <Heading preheading={preHeading} className="mb-md">
            {parse(heading)}
          </Heading>

          <p>{parse(content)}</p>
        </div>

        <div className={`mt-xl ${cx.flywheelWrapper}`}>
          <Flywheel
            maxWidth="575px"
            number={4}
            heading="Create A Revenue-Generating Machine"
            content="Own first-party, accurate ad data and put budget into genuine users"
            image={revenue}
            first
          />

          <Flywheel
            maxWidth="575px"
            number={1}
            heading="Exclude Invalid & Fradulent Traffic"
            content="Focus on high-quality clicks by blocking no-value traffic"
            image={excludeTraffic}
            arrowDirection="right"
          />

          <Flywheel
            maxWidth="575px"
            number={2}
            heading="Attract Your Ideal Customers"
            content="See ROI improve and cheaper CPAs as you reinvest wasted budget"
            image={attractCustomers}
            rightImage
            arrowDirection="bottom"
          />

          <Flywheel
            maxWidth="575px"
            number={4}
            heading="Create A Revenue-Generating Machine"
            content="Own first-party, accurate ad data and put budget into genuine users"
            image={revenue}
            arrowDirection="top"
            className={cx.box4}
          />

          <Flywheel
            maxWidth="575px"
            number={3}
            heading="Feed Optimisations With Good Data"
            content="Optimise and scale campaigns with quality data based on real customers"
            image={feedOptimization}
            rightImage
            arrowDirection="left"
            className={cx.box3}
          />

          <Flywheel
            maxWidth="575px"
            number={1}
            heading="Exclude Invalid & Fradulent Traffic"
            content="Focus on high-quality clicks by blocking no-value traffic"
            image={excludeTraffic}
            last
          />
        </div>
      </SectionContent>
      <SectionContent paddingTop="none" paddingBot="md">
        <Rows>
          <Columns count="2">
            <CTA
              title={iconText.heading}
              text={iconText.content}
              buttonLink={iconText.buttonURL}
              buttonText={iconText.buttonText}
              image={invalidAudiences}
            />

            <CTA
              title={iconTextTwo.heading}
              text={iconTextTwo.content}
              buttonLink={iconTextTwo.buttonURL}
              buttonText={iconTextTwo.buttonText}
              image={ownYourData}
            />

            <CTA
              title={iconTextThree.heading}
              text={iconTextThree.content}
              buttonLink={iconTextThree.buttonURL}
              buttonText={iconTextThree.buttonText}
              image={crossPlatformAnalytics}
            />

            <CTA
              title={iconTextFour.heading}
              text={iconTextFour.content}
              buttonLink={iconTextFour.buttonURL}
              buttonText={iconTextFour.buttonText}
              image={revenueThroughExclusion}
            />
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

FlywheelTwo.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default FlywheelTwo
