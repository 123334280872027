import React from "react"
import Carousel from "./default"
import CaseStudyCard from "./case-study-card"

function CaseStudyCarousel({ cardsData, alt }) {
  const cards = cardsData.map(
    (
      {
        logo,
        authorText,
        link,
        testimonialQuoteText,
        btnHex,
        btnHexHover,
        jobTitle,
      },
      index,
    ) => (
      <CaseStudyCard
        image={logo}
        title={authorText}
        subTitle={jobTitle}
        text={testimonialQuoteText}
        link={link}
        key={index}
        btnColor={btnHex}
        btnColorHover={btnHexHover}
        alt={alt}
      />
    ),
  )

  return <Carousel centered slides={cards} />
}

export default CaseStudyCarousel
