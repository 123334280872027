import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React, { useLayoutEffect, useRef } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import Card from "../../../components/containers/card"
import Section from "../../../components/containers/section"
import SectionContent from "../../../components/containers/section-content"
import Heading from "../../../components/content/heading"
import { Columns, Rows } from "../../../components/grid"
import Button from "../../../components/interactive/button"

gsap.registerPlugin(ScrollTrigger)

const useStyles = createUseStyles(() => ({
  knight: {
    position: "absolute",
    bottom: -1,
    zIndex: 2,
  },
  card: {
    background: "black",
    overflow: "hidden",
    position: "relative",
  },
  book: {
    position: "relative",
    bottom: "-75%",
    display: "block",
  },
  // Empty so we can use the mobile selector
  columns: {},
  "@media (max-width: 768px)": {
    book: {
      bottom: "-10%",
    },
  },
  "@media (max-width: 1024px)": {
    columns: {
      gridTemplateColumns: "unset !important",
    },
    knight: {
      display: "none",
    },
    book: {
      transform: "translate(39%, 28.5%) scale(1.8)",
    },
    card: {
      borderRadius: 0,
    },
    bgPrismOne: {
      transform: "translate(0%, -100%) scale(2) rotate(-10deg)",
    },
    bgPrismTwo: {
      display: "none",
    },
  },
  "@media (min-width: 1280px)": {
    book: {
      transform: "translate(6%, 1px)",
    },
  },
}))

function Guide({ data }) {
  const cx = useStyles()
  const bookRef = useRef()
  const { buttonText, buttonURL, content, guideCover, heading, OpenInNewTab } =
    data
  const img = getImage(guideCover.localFile)

  useLayoutEffect(() => {
    gsap.to(bookRef.current, {
      scrollTrigger: {
        trigger: bookRef.current,
        start: "top bottom",
        end: "center center",
        scrub: true,
        toggleActions: "play none none reverse",
      },
      bottom: "-3rem",
    })
  }, [])

  return (
    <Section className="py-lg">
      <SectionContent width="wide" className="pc-px-xxl px-none">
        <Card
          styleType="solid"
          className={`mob-px-md pc-px-xxl tab-pt-xl ${cx.card}`}
        >
          <Columns count="2" alignment="center" className={cx.columns}>
            <SectionContent className="mob-px-sm tab-px-xxl">
              <Rows gap="sm" className="pb-md">
                <Heading level={3} className="text--color-white">
                  {parse(heading)}
                </Heading>
                <p className="text--color-white" style={{ opacity: "66%" }}>
                  {parse(content)}
                </p>
              </Rows>
              <Button
                styletype="solid"
                color="core"
                href={buttonURL}
                openTab={OpenInNewTab}
              >
                {buttonText}
              </Button>
            </SectionContent>
            <div>
              <StaticImage
                src="../../../images/content-hub/knight_white.png"
                alt="chess knight"
                height="100%"
                className={cx.knight}
              />
              <div ref={bookRef} className={cx.book}>
                <GatsbyImage image={img} alt="lunio brochure" />
              </div>
            </div>
          </Columns>
        </Card>
      </SectionContent>
    </Section>
  )
}

Guide.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default Guide
