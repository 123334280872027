import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  handle: {
    backgroundColor: theme.colors.core.core,
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      '&:first-of-type': {
        transform: 'rotate(180deg)',
        marginRight: 2,
      },

      color: theme.colors.lights.white,
    },
  },
}));

function Handle() {
  const cx = useStyles();

  return (
    <div className={cx.handle}>
      <FontAwesomeIcon icon={faCaretRight} />
      <FontAwesomeIcon icon={faCaretRight} />
    </div>
  );
}

export default Handle;
