import React, { useLayoutEffect, useState } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Section from "@components/containers/section"
import Button from "@components/interactive/button"
import SectionContent from "@components/containers/section-content"
import Heading from "@components/content/heading"
import { createUseStyles } from "react-jss"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SectionBackground from "@components/containers/section-background"
import parse from "html-react-parser"
import PropTypes from "prop-types"

gsap.registerPlugin(ScrollTrigger)

const useStyles = createUseStyles(theme => ({
  wrapper: {
    background: `linear-gradient(#1d1d1d, ${theme.colors.core.coreDark})`,
    width: "100%",
    maxWidth: 1600,
    borderRadius: 50,

    "& * ": {
      color: "#fff",
    },

    "& p": {
      fontSize: 18,
    },
  },
  heading: {
    textAlign: "center",
  },
  header: {
    textAlign: "center",
    maxWidth: 725,
    margin: "0 auto",
    marginBottom: theme.spacing.lg,
  },
  button: {
    backgroundColor: "transparent",
    fontSize: 20,
  },
  section: {
    minHeight: "100vh",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    overflowX: "hidden",
    overflowY: "visible",
  },
  sectionRight: {
    extend: "section",

    "& $sectionText": {
      marginLeft: "auto",
    },
  },
  sectionText: {
    maxWidth: 568,
  },
  image: {
    maxWidth: 729,
    position: "absolute",
    right: "-5%",
  },
  image2: {
    position: "absolute",
    left: "-5%",
  },
  image3: {
    position: "absolute",
    right: "-30%",
  },
  fire: {
    position: "absolute",
    left: 425,
    top: 346,
    zIndex: 2,
  },
  siren: {
    position: "absolute",
    left: 50,
    top: 138,
    zIndex: 2,
  },
  rosette: {
    position: "absolute",
    left: "-20px",
    top: 260,
    zIndex: 2,
  },
  globe: {
    position: "absolute",
    left: "530px",
    top: "-167px",
    zIndex: 2,
  },
  google: {
    position: "absolute",
    left: "-40px",
    top: "-200px",
    zIndex: 2,
  },
  reddit: {
    position: "absolute",
    left: 350,
    top: "-250px",
    zIndex: 2,
  },
  linkedin: {
    position: "absolute",
    left: 250,
    bottom: "-150px",
    zIndex: 2,
  },
  microscope: {
    position: "absolute",
    left: 50,
    bottom: 0,
    zIndex: 2,
  },
  "@media(max-width: 1280px)": {
    button: {
      padding: 0,
    },
    image: {
      position: "relative",
    },
    image2: {
      position: "relative",
    },
    image3: {
      position: "relative",
    },
    google: {
      position: "relative",
      top: "-20px",
    },
    reddit: {
      position: "relative",
      top: "-30px",
      left: 0,
    },
    linkedin: {
      position: "relative",
      top: 450,
      left: -400,
    },
    section: {
      overflowX: "visible",
    },
    sectionRight: {
      overflowX: "visible",
    },
  },

  "@media(max-width: 1070px)": {
    microscope: {
      display: "none",
    },
  },

  "@media(max-width: 768px)": {
    wrapper: {
      paddingTop: theme.spacing.xl,
    },
    heading: {
      textAlign: "left",
      fontSize: 32,
      lineHeight: "48px",

      "& br": {
        display: "none",
      },
    },
    header: {
      textAlign: "left",
    },
    image3: {
      bottom: 50,
      width: "150%",
    },
    fire: {
      display: "none",
    },
    siren: {
      display: "none",
    },
    microscope: {
      display: "none",
    },
    rosette: {
      display: "none",
    },
    globe: {
      display: "none",
    },
    google: {
      width: "40%",
      top: "-10px",
    },
    reddit: {
      width: "40%",
      top: "-10px",
    },
    linkedin: {
      width: "50%",
      top: 10,
      left: 0,
    },
    play: {
      "& svg": {
        fontSize: 50,
      },
    },
  },

  "@media(max-width: 640px)": {
    wrapper: {
      borderRadius: 0,
    },
  },
}))

function YouTubeVid(props) {
  const { data } = props
  const {
    buttonText,
    buttonURL,
    heading,
    youTubeVideoURL,
    youTubeCover,
    contentOne,
    contentThree,
    contentTwo,
  } = data

  const cx = useStyles()

  return (
    <Section>
      <div className={cx.wrapper}>
        <SectionContent paddingTop="lg" paddingBot="xs">
          <div className={cx.header}>
            <Heading level="2" alignment="center">
              {parse(heading)}
            </Heading>

            <Button href={buttonURL} styletype="" className={cx.button}>
              {buttonText}
            </Button>
          </div>
        </SectionContent>

        <div className={cx.section}>
          <SectionContent>
            <div className={cx.sectionText}>
              <Heading level="3" className="h2 text--color-white mb-md">
                {parse(contentOne.header)}
              </Heading>
              <p>{contentOne.content}</p>
            </div>
          </SectionContent>

          <div className={cx.image}>
            <StaticImage
              className={cx.fire}
              alt=""
              src="../../../images/content-hub/genuine_protection_fire.png"
            />

            <StaticImage
              className={cx.siren}
              alt=""
              src="../../../images/content-hub/genuine_protection_siren.png"
            />

            <StaticImage
              className=""
              alt=""
              src="../../../images/content-hub/genuine_protection_dash.png"
            />
          </div>
        </div>

        <div className={cx.sectionRight}>
          <SectionContent>
            <div className={cx.sectionText}>
              <Heading level="3" className="h2 text--color-white mb-md">
                {parse(contentTwo.header)}
              </Heading>
              <p>{contentTwo.content}</p>
            </div>
          </SectionContent>

          <div className={cx.image2}>
            <StaticImage
              className={cx.google}
              alt=""
              src="../../../images/content-hub/analyze_google_health.png"
            />

            <StaticImage
              className={cx.reddit}
              alt=""
              src="../../../images/content-hub/analyze_reddit_health.png"
            />

            <StaticImage
              className=""
              alt=""
              src="../../../images/content-hub/analyze_dash.png"
            />

            <StaticImage
              className={cx.linkedin}
              alt=""
              src="../../../images/content-hub/analyze_linkedin_health.png"
            />

            <StaticImage
              className={cx.microscope}
              alt=""
              src="../../../images/content-hub/analyze_microscope.png"
            />
          </div>
        </div>

        <div className={cx.section}>
          <SectionContent>
            <div className={cx.sectionText}>
              <Heading level="3" className="h2 text--color-white mb-md">
                {parse(contentThree.header)}
              </Heading>
              <p>{contentThree.content}</p>
            </div>
          </SectionContent>

          <div className={cx.image3}>
            <StaticImage
              className={cx.globe}
              alt=""
              src="../../../images/content-hub/insight_globe.png"
            />

            <StaticImage
              className={cx.rosette}
              alt=""
              src="../../../images/content-hub/insight_rosette.png"
            />

            <StaticImage
              className=""
              alt=""
              src="../../../images/content-hub/insight_dash.png"
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

YouTubeVid.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default YouTubeVid
