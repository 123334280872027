import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Element, scroller, Events } from "react-scroll"
import StickyNav from "../components/interactive/sticky-nav"
import Main from "../components/layout/main"
import Seo from "../components/seo/seo"
import AboveTheFold from "../sections/content-hub/AboveTheFold"
import AdNetworks from "../sections/content-hub/AdNetworks"
import AdPlatformsDont from "../sections/content-hub/AdPlatformsDont"
import BottomCTA from "../sections/content-hub/BottomCTA"
import CTA from "../sections/content-hub/CTA"
import FlywheelOne from "../sections/content-hub/FlywheelOne"
import FlywheelTwo from "../sections/content-hub/FlywheelTwo"
import Guide from "../sections/content-hub/Guide"
import ImageDifference from "../sections/content-hub/ImageDifference"
import LackOfTransparency from "../sections/content-hub/LackOfTransparency"
import Quote from "../sections/content-hub/Quote"
import SuccessStories from "../sections/content-hub/SuccessStories"
import WeHelp from "../sections/content-hub/WeHelp"
import YouTubeVid from "../sections/content-hub/YouTubeVid"
import SiteFooterMinimal from "../components/layout/footer/footer-minimal"

function AdvertisingDataTransparency({ data, location }) {
  const {
    Seo: seo,
    AboveTheFold: aboveTheFold,
    WhosTalkingAboutUs,
    CTAGroup,
    YouTubeSecton,
    WeHelp: weHelp,
    SecondCTA,
    InsideYourData,
    AnimatedCards,
    AdPlatformsDont: adPlatformsDont,
    LackOfTransparency: lackOfTransparency,
    QuoteBlock,
    CTABanner,
    GuideCTA,
    FlyWheelOne: flyWheelOne,
    FlyWheelTwo: flyWheelTwo,
    ImageDifference: imageDifference,
  } = data.strapiPageAdDataTransparency

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  function navigateToElement(name) {
    let offsetPx = -50
    if (name === "AdNetworks") {
      offsetPx = 600
    }
    if (name === "FlywheelTwo") {
      offsetPx = -100
    }
    if (name === "FlywheelOne") {
      offsetPx = -140
    }
    if (name === "YouTubeVid") {
      offsetPx = -120
    }
    scroller.scrollTo(name, {
      duration: 800,
      delay: 0,
      offset: offsetPx,
      smooth: "easeInOutQuart",
    })
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <Element name="WhatsMissing">
        <header>
          <AboveTheFold data={aboveTheFold} />
        </header>
      </Element>
      <StickyNav navigateToElement={navigateToElement} />
      <Element name="AdNetworks" style={{ display: "initial" }}>
        <AdNetworks data={AnimatedCards} insideData={InsideYourData} />
      </Element>
      <Element name="AdPlatformsDont">
        <AdPlatformsDont data={adPlatformsDont} />
      </Element>
      <Element name="LackOfTransparency">
        <LackOfTransparency data={lackOfTransparency} />
      </Element>
      <Element name="Quote">
        <Quote data={QuoteBlock} />
      </Element>
      <Element name="CTA1">
        <CTA
          heading={CTABanner.heading}
          content={CTABanner.content}
          linkText={CTABanner.buttonText}
          linkHref={CTABanner.buttonURL}
          alternateBackground
        />
      </Element>
      <Element name="FlywheelOne">
        <FlywheelOne data={flyWheelOne} />
      </Element>
      <Element name="ImageDifference">
        <ImageDifference data={imageDifference} />
      </Element>
      <Element name="Guide" id="Guide">
        <Guide data={GuideCTA} />
      </Element>
      <Element name="FlywheelTwo">
        <FlywheelTwo data={flyWheelTwo} />
      </Element>
      <Element name="CTA2" id="CTA2">
        <CTA
          heading={SecondCTA.heading}
          content={SecondCTA.content}
          linkText={SecondCTA.buttonText}
          linkHref={SecondCTA.buttonURL}
        />
      </Element>
      <Element name="WeHelp">
        <WeHelp data={weHelp} />
      </Element>
      <Element name="YouTubeVid">
        <YouTubeVid data={YouTubeSecton} />
      </Element>
      <Element name="SuccessStories">
        <SuccessStories data={WhosTalkingAboutUs} />
      </Element>
      <Element name="BottomCTA">
        <BottomCTA data={CTAGroup} />
      </Element>
    </Main>
  )
}

export const query = graphql`
  query PageContentHub {
    strapiPageAdDataTransparency {
      AboveTheFold {
        content
        heading
        textColumTwo {
          OpenInNewTab
          boldHeading
          buttonText
          buttonURL
          content
        }
        textColumnOne {
          OpenInNewTab
          boldHeading
          buttonText
          buttonURL
          content
        }
        textColumnThree {
          OpenInNewTab
          boldHeading
          buttonText
          buttonURL
          content
        }
      }
      AdPlatformsDont {
        colOneText
        buttonText
        buttonURL
        colTwoText
        heading
        preHeading
      }
      AnimatedCards {
        content
        heading
        preHeading
      }
      CTAGroup {
        buttonOneText
        buttonOneURL
        buttonTwoText
        buttonTwoURL
        heading
      }
      FlyWheelOne {
        colOneText
        colTwoText
        heading
        preHeading
      }
      FlyWheelTwo {
        content
        heading
        iconText {
          buttonText
          buttonURL
          content
          heading
          imageIcon {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
            alternativeText
          }
        }
        iconTextFour {
          buttonText
          buttonURL
          content
          heading
          imageIcon {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, placeholder: BLURRED)
              }
            }
          }
        }
        iconTextThree {
          buttonText
          buttonURL
          content
          heading
          imageIcon {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, placeholder: BLURRED)
              }
            }
          }
        }
        iconTextTwo {
          buttonText
          buttonURL
          content
          heading
          imageIcon {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
          }
        }
        preHeading
      }
      GuideCTA {
        buttonText
        buttonURL
        OpenInNewTab
        content
        guideCover {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        heading
      }
      ImageDifference {
        imageOne {
          localFile {
            publicURL
          }
        }
        content
        heading
        preHeading
        imageTwo {
          localFile {
            publicURL
          }
        }
      }
      InsideYourData {
        content
        heading
        preHeading
        IconsWithText {
          boldHeading
          content
          icon {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
          }
        }
      }
      LackOfTransparency {
        content
        header
      }
      QuoteBlock {
        buttonText
        buttonURL
        company
        quote
        quoteName
        headshot {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      SecondCTA {
        buttonText
        buttonURL
        content
        OpenInNewTab
        heading
      }
      Seo {
        id
        metaDesc
        metaTitle
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      StickyNav {
        Buttons {
          buttonText
          buttonURL
          id
        }
      }
      WeHelp {
        content
        heading
        id
        imageIcon {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED)
            }
          }
        }
      }
      WhosTalkingAboutUs {
        content
        headshot {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED)
            }
          }
        }
        name
        position
      }
      CTABanner {
        buttonText
        buttonURL
        content
        heading
        OpenInNewTab
      }
      YouTubeSecton {
        buttonText
        buttonURL
        heading
        youTubeVideoURL
        youTubeCover {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
        contentOne {
          content
          header
        }
        contentThree {
          content
          header
        }
        contentTwo {
          content
          header
        }
      }
    }
  }
`

AdvertisingDataTransparency.propTypes = {
  /**
   * Data for the page
   */
  data: PropTypes.object.isRequired,
}

export default AdvertisingDataTransparency
