import React from "react"
import { createUseStyles } from "react-jss"
import { useStaticQuery, graphql } from "gatsby"
import { faMountains } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Link from "../../content/link"
import { Columns } from "../../grid"
import theme from "../../../theme"

const useStyles = createUseStyles(() => ({
  direction: {},
  "@media (max-width: 768px)": {
    direction: {
      "flex-direction": "column !important",
    },
  },
}))

function FooterPostBarMinimal() {
  const cx = useStyles()

  const {
    strapiFooter: { PrivacyLink, TermsLink, DataProtection },
  } = useStaticQuery(graphql`
    query FooterQuery {
      strapiFooter {
        PrivacyLink {
          text
          href
        }
        TermsLink {
          href
          text
        }
        DataProtection {
          text
          href
        }
      }
    }
  `)

  return (
    <Columns className="footer__postbar" count="1" alignment="center">
      <div className="">
        <div className={`footer__copyright ${cx.direction}`}>
          <p className="footer__link footer__link--fake footer__link--fake-minimal">
            © Lunio {new Date().getFullYear()}
          </p>
          <FontAwesomeIcon
            icon={faMountains}
            size="2x"
            style={{
              "--fa-primary-color": theme.colors.darks.lightGrey,
              "--fa-secondary-color": theme.colors.darks.lightGrey,
            }}
            className="footer__copyright__seperator"
          />
          <Link
            className="footer__link footer__link--minimal"
            to={PrivacyLink.href}
          >
            {PrivacyLink.text}
          </Link>
          <FontAwesomeIcon
            icon={faMountains}
            size="2x"
            style={{
              "--fa-primary-color": theme.colors.darks.lightGrey,
              "--fa-secondary-color": theme.colors.darks.lightGrey,
            }}
            className="footer__copyright__seperator "
          />
          <Link
            className="footer__link footer__link--minimal"
            to={DataProtection.href}
          >
            {DataProtection.text}
          </Link>
          <FontAwesomeIcon
            icon={faMountains}
            size="2x"
            style={{
              "--fa-primary-color": theme.colors.darks.lightGrey,
              "--fa-secondary-color": theme.colors.darks.lightGrey,
            }}
            className="footer__copyright__seperator "
          />
          <Link
            className="footer__link footer__link--minimal"
            to={TermsLink.href}
          >
            {TermsLink.text}
          </Link>
        </div>
      </div>
    </Columns>
  )
}

export default FooterPostBarMinimal
