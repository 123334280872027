import React from 'react';
import Section from '@components/containers/section';
import CssAccentGroup from '@components/accents/css-accent-group';
import SectionContent from '@components/containers/section-content';
import Heading from '@components/content/heading';
import CaseStudyCarousel from '@components-smart/carousel/case-study-carousel';
import rawTheme from '@src/theme';

function SuccessStories(props) {
  const { data } = props;

  const mappedData = data.map((item) => ({
    authorText: item.name,
    jobTitle: item.position,
    testimonialQuoteText: item.content,
    logo: item.headshot,
  }));

  const accentData = [
    {
      size: 15,
      color: rawTheme.colors.accents.ragnarok,
      opacity: 0.8,
      top: 0,
      type: 'heart',
    },
    {
      size: 11,
      color: rawTheme.colors.accents.ragnarok,
      opacity: 0.6,
      top: 5,
      left: '-30px',
    },
  ];

  return (
    <Section>
      <SectionContent paddingTop="sm" paddingBot="xxs" className="pb-xs mob-pb-none">
        <CssAccentGroup
          transform="translate(-5%, 50%)"
          data={accentData}
          className="hidden--tab-down"
        />
        <Heading level={2} className="mb-md">
          Who&apos;s Talking About Us

        </Heading>
        <CaseStudyCarousel cardsData={mappedData} alt />
      </SectionContent>
    </Section>
  );
}

export default SuccessStories;
