import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { createUseStyles } from "react-jss"
import CssAccentGroup from "@components/accents/css-accent-group"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import Section from "../../../components/containers/section"
import SectionBackground from "../../../components/containers/section-background"
import SectionContent from "../../../components/containers/section-content"
import { Columns, Content, Footer } from "../../../components/grid"
import Heading from "../../../components/content/heading"
import Copy from "../../../components/content/copy"
import Button from "../../../components/interactive/button"
import CtaGroup from "../../../components/containers/cta-group"
import LineAccent from "../../../components/accents/line-accent"
import GlobeLines from "../../../components/accents/globe-lines"

const useStyles = createUseStyles(theme => ({
  background: {
    background: "linear-gradient(90deg, #30154f, #6d3b9f)",
  },
  "@media (max-width: 768px)": {
    background: {
      background: "linear-gradient(180deg, #472461, #5f3387)",
    },
  },
}))

function AboveTheFold({ data }) {
  const {
    content,
    heading,
    textColumnOne,
    textColumTwo: textColumnTwo,
    textColumnThree,
  } = data

  const cx = useStyles()

  return (
    <Section wave="blog" theme="dark" className={`hide-wave ${cx.background}`}>
      <SectionBackground>
        <div className="absolute width--full height--full hero-image hero-image--pricingbg" />
        <div className="hero-image hero-image--content-hub">
          <StaticImage
            src="../../../images/content-hub/globe.png"
            alt="globe"
          />
        </div>
        <div className="hero-image hero-image--content-hub-clicks hidden--pc-down">
          <StaticImage
            src="../../../images/content-hub/globe_clicks.png"
            alt="clicks log"
          />
          <GlobeLines />
        </div>
      </SectionBackground>
      <SectionContent hero paddingBot="xxl" paddingTop="md">
        <Columns count="3,1">
          <Content gap="xl" paddingLeft="none">
            <Columns count="3,1">
              <Content className="mt-xl" paddingLeft="none" paddingRight="none">
                <Heading level={1}>{parse(heading)}</Heading>
                <Copy>
                  <p className="text--small pc-pr-lg">{content}</p>
                </Copy>
              </Content>
            </Columns>
            <LineAccent />
            <Footer>
              <CtaGroup>
                <Columns count="3" alignment="start">
                  <Content
                    paddingLeft="none"
                    gap="xs"
                    className="pb-lg mob-pb-none"
                  >
                    <p className="text--small text--strong">
                      {textColumnOne.boldHeading}
                    </p>
                    <p className="text--xs">{textColumnOne.content}</p>
                    <Button
                      href={textColumnOne.buttonURL}
                      styletype=""
                      className="pl-none"
                      color="terra"
                      openTab={textColumnOne.OpenInNewTab}
                    >
                      {textColumnOne.buttonText}
                    </Button>
                  </Content>
                  <Content
                    paddingLeft="none"
                    gap="xs"
                    className="pb-lg mob-pb-none"
                  >
                    <p className="text--small text--strong">
                      {textColumnTwo.boldHeading}
                    </p>
                    <p className="text--xs">{textColumnTwo.content}</p>
                    <Button
                      href={textColumnTwo.buttonURL}
                      styletype=""
                      className="pl-none"
                      color="terra"
                      openTab={textColumnTwo.OpenInNewTab}
                    >
                      {textColumnTwo.buttonText}
                    </Button>
                  </Content>
                  <Content paddingLeft="none" gap="xs">
                    <p className="text--small text--strong">
                      {textColumnThree.boldHeading}
                    </p>
                    <p className="text--xs">{textColumnThree.content}</p>
                    <Button
                      href={textColumnThree.buttonURL}
                      styletype=""
                      className="pl-none"
                      color="terra"
                      openTab={textColumnThree.OpenInNewTab}
                    >
                      {textColumnThree.buttonText}
                    </Button>
                  </Content>
                </Columns>
              </CtaGroup>
            </Footer>
          </Content>
        </Columns>
      </SectionContent>
    </Section>
  )
}

AboveTheFold.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default AboveTheFold
