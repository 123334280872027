import React from 'react';
import ImageCard from '@components/containers/ImageCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faInfinity } from '@fortawesome/free-solid-svg-icons';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'inline-block',
    position: 'relative',
    maxWidth: 'calc(50% - 30px)',
    width: '100%',
  },
  right: {
    marginRight: 30,

    '& $arrow': {
      right: '-67px',
      bottom: 100,
    },
  },
  left: {
    marginLeft: 30,

    '& $arrow': {
      left: '-67px',
      transform: 'rotate(180deg)',
      bottom: 100,
    },
  },
  bottom: {
    marginBottom: 70,

    '& $arrow': {
      left: 'calc(50% - 50px)',
      transform: 'rotate(90deg)',
      bottom: '-40px',
    },
  },
  top: {
    '& $arrow': {
      left: 'calc(50% - 30px)',
      transform: 'rotate(270deg)',
      top: '-40px',
    },
  },
  arrow: {
    height: 4,
    zIndex: -1,
    opacity: 0.4,
    width: 100,
    backgroundColor: theme.colors.accents.terra,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      color: theme.colors.accents.terra,
      fontSize: 30,
    },
  },
  first: {
    display: 'none',
  },
  last: {
    display: 'none',
  },
  firstCover: {
    position: 'absolute',
    top: -20,
    right: 0,
    bottom: 0,
    left: 0,
    background: `linear-gradient(${theme.colors.lights.white}, ${theme.colors.lights.white}E6, ${theme.colors.lights.white}00)`,
  },
  lastCover: {
    position: 'absolute',
    top: -20,
    right: 0,
    bottom: 0,
    left: 0,
    background: `linear-gradient(${theme.colors.lights.white}00, ${theme.colors.lights.white}E6, ${theme.colors.lights.white})`,
  },
  '@media (max-width: 768px)': {
    container: {
      maxWidth: 'none',
      width: '100%',
      margin: '0',
      marginBottom: 60,
    },
    first: {
      display: 'block',

      '& $arrow': {
        display: 'none',
      },
    },
    last: {
      display: 'block',
      order: 10,

      '& svg': {
        transform: 'rotate(90deg) !important',
      },
    },
    arrow: {
      transform: 'rotate(90deg) !important',
      top: '-30px !important',
      right: '0 !important',
      left: '0 !important',
      margin: '0 auto',
      backgroundColor: theme.colors.darks.lightGreyInt,
      opacity: 1,

      '& svg': {
        color: theme.colors.darks.lightGreyInt,
      },
    },
  },
}));

function Flywheel(props) {
  const {
    arrowDirection, className, first, last, ...rest
  } = props;
  const cx = useStyles();

  const classes = classNames(
    cx.container,
    { [cx.first]: first },
    { [cx.last]: last },
    { [cx[arrowDirection]]: arrowDirection },
    className,
  );

  return (
    <div className={classes}>
      <ImageCard {...rest} />

      <div className={cx.arrow}>
        <FontAwesomeIcon icon={last ? faInfinity : faCaretRight} rotate={180} />
      </div>
      { first
        && <div className={cx.firstCover} />}

      { last
        && <div className={cx.lastCover} />}
    </div>
  );
}

Flywheel.propTypes = {
  /**
   * Additional classes
   */
  className: PropTypes.string,
  /**
   * Direction for the arrow
   */
  arrowDirection: PropTypes.string,
  /**
   * Whether this is the first card (for mobile)
   */
  first: PropTypes.bool,
  /**
   * Whether this is the last card (for mobile)
   */
  last: PropTypes.bool,
};

Flywheel.defaultProps = {
  className: null,
  first: false,
  last: false,
  arrowDirection: null,
};

export default Flywheel;
