import React from "react"
import PropTypes from "prop-types"
import ImageCard from "@components/containers/ImageCard"
import { createUseStyles } from "react-jss"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faInfinity } from "@fortawesome/free-solid-svg-icons"

const useStyles = createUseStyles(theme => ({
  container: {
    display: "block",
    width: "100%",
  },
  content: {
    display: "inline-block",
    position: "relative",
    maxWidth: 886,
    width: "100%",
    marginBottom: 175,
  },
  line: {
    height: 4,
    width: 72,
    backgroundColor: "#EAE7ED",
    position: "absolute",
    left: "-70px",
    top: "100px",
  },
  longLine: {
    width: 400,
    left: "-364px",
  },
  square: {
    width: 40,
    height: 40,
    backgroundColor: "#EAE7ED",
    position: "absolute",
    bottom: "-19px",
    left: "-19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
  },
  icon: {
    color: theme.colors.core.coreLight,
    fontSize: "20px",
  },
  first: {
    display: "none",
  },
  last: {
    display: "none",
  },
  arrow: {
    display: "none",
  },
  firstCover: {
    position: "absolute",
    zIndex: 2,
    top: -20,
    right: 0,
    bottom: 0,
    left: 0,
    background: `linear-gradient(${theme.colors.lights.white}, ${theme.colors.lights.white}E6, ${theme.colors.lights.white}00)`,
  },
  lastCover: {
    position: "absolute",
    zIndex: 2,
    top: -20,
    right: 0,
    bottom: 0,
    left: 0,
    background: `linear-gradient(${theme.colors.lights.white}00, ${theme.colors.lights.white}E6, ${theme.colors.lights.white})`,
  },
  "@media (max-width: 768px)": {
    container: {
      maxWidth: "none",
      width: "100%",
      marginBottom: 60,
    },
    content: {
      marginBottom: 0,
    },
    first: {
      display: "block",

      "& $arrow": {
        display: "none",
      },
    },
    last: {
      display: "block",
      order: 10,

      "& svg": {
        transform: "rotate(0) !important",
        right: "17px !important",
      },
    },
    arrow: {
      position: "absolute",
      display: "block",
      width: 4,
      height: 100,
      top: "-60px",
      right: 0,
      left: 0,
      margin: "0 auto",
      backgroundColor: theme.colors.darks.lightGreyInt,
      opacity: 1,

      "& svg": {
        color: theme.colors.darks.lightGreyInt,
        fontSize: 30,
        transform: "rotate(90deg)",
        position: "relative",
        right: 3,
        top: 20,
      },
    },
  },
}))

function Flywheel(props) {
  const { className, rightImage, first, last, icon, ...rest } = props
  const cx = useStyles()

  const classes = classNames(
    cx.container,
    { [cx.first]: first },
    { [cx.last]: last },
    { "text--right": rightImage },
    className,
  )

  const lineClasses = classNames(cx.line, { [cx.longLine]: rightImage })

  return (
    <div className={classes}>
      <div className={cx.content}>
        <div className={lineClasses}>
          <div className={cx.square}>
            <FontAwesomeIcon className={cx.icon} icon={icon} />
          </div>
        </div>

        <div className={cx.arrow}>
          <FontAwesomeIcon icon={last ? faInfinity : faCaretRight} />
        </div>

        <ImageCard
          {...rest}
          contentMaxWidth={535}
          rightImage={rightImage}
          centerVertical
        />

        {first && <div className={cx.firstCover} />}

        {last && <div className={cx.lastCover} />}
      </div>
    </div>
  )
}

Flywheel.propTypes = {
  /**
   * Additional classes
   */
  className: PropTypes.string,
  /**
   * Whether the image should display on the right
   */
  rightImage: PropTypes.bool,
  /**
   * Whether this is the first card (for mobile)
   */
  first: PropTypes.bool,
  /**
   * Whether this is the last card (for mobile)
   */
  last: PropTypes.bool,
  /**
   * Icon to display
   */
  icon: PropTypes.object,
}

Flywheel.defaultProps = {
  className: null,
  rightImage: false,
  first: false,
  last: false,
  icon: null,
}

export default Flywheel
