import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { createUseStyles } from "react-jss"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import Section from "../../../components/containers/section"
import SectionContent from "../../../components/containers/section-content"
import Copy from "../../../components/content/copy"
import Heading from "../../../components/content/heading"
import { Columns, Content } from "../../../components/grid"
import DeckCards from "../../../components/animated/deck-cards"

const useStyles = createUseStyles({
  section: {
    position: "sticky",
    minHeight: "100vh",
    top: -50,
    zIndex: -1,
  },
  accentContainer: {
    order: 2,
    position: "relative",
  },
  sectionContainer: {
    order: 1,
    padding: 0,
  },
  accent: {
    position: "absolute",
    top: 100,
    right: 0,
  },
  "@media (max-width: 1024px)": {
    accentContainer: {
      order: 1,
    },
    sectionContainer: {
      order: 2,
    },
    accent: {
      top: 40,
      right: 10,
    },
    section: {
      position: "static",
      minHeight: "none",
    },
  },
})

function AdNetworks({ data, insideData }) {
  const { heading, content, preHeading } = data
  const {
    content: insideContent,
    heading: insideHeading,
    preHeading: insidepreHeading,
    IconsWithText,
  } = insideData
  const cx = useStyles()

  return (
    <>
      <Section>
        <SectionContent paddingTop="md" paddingBot="none">
          <Columns count="2">
            <Content>
              <Heading preheading={preHeading}>{parse(heading)}</Heading>
              <Content gap="xl" paddingLeft="none" paddingRight="none">
                <p>{parse(content)}</p>
              </Content>
            </Content>
            <DeckCards />
          </Columns>
        </SectionContent>
        <SectionContent paddingTop="sm" paddingBot="xl">
          <Columns count="4,1">
            <SectionContent paddingTop="xxs" paddingBot="xxs">
              <Heading preheading={insidepreHeading} className="mb-sm">
                {parse(insideHeading)}
              </Heading>
              <Copy>
                <p>{insideContent}</p>
              </Copy>
            </SectionContent>
          </Columns>
          <Content>
            <Columns count="4">
              {IconsWithText.map(section => (
                <Content
                  paddingRight="none"
                  className="mb-sm pc-mb-none"
                  gap="sm"
                >
                  <div className="mb-xxs">
                    <GatsbyImage
                      image={
                        section.icon.localFile.childImageSharp.gatsbyImageData
                      }
                    />
                  </div>
                  <p className="text--24 text--strong">{section.boldHeading}</p>
                  <p className="text--small">{section.content}</p>
                </Content>
              ))}
            </Columns>
          </Content>
        </SectionContent>
      </Section>
    </>
  )
}

AdNetworks.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
  /**
   * insideData
   */
  insideData: PropTypes.object.isRequired,
}

export default AdNetworks
