import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"
import { createUseStyles } from "react-jss"
import Wrapper from "./Wrapper"

const useStyles = createUseStyles(theme => ({
  pillar: props => ({
    width: props.width,
    height: props.height,
    backgroundColor: theme.colors.core.coreLight,
    display: "inline-block",
    position: "relative",
    verticalAlign: "bottom",

    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      backgroundColor: "#6D4495",
      width: props.pillarSide.width,
      height: props.height,
      top: props.pillarSide.top,
      left: "100%",
      transform: "skew(180deg,208deg)",
    },
  }),
  title: props => ({
    "&&": {
      fontSize: 40,
      textAlign: "center",
      lineHeight: "64px",
      padding: props.padding,
    },
  }),
  bottom: {
    "&&": {
      fontSize: 48,
      textAlign: "center",
      opacity: 0.5,

      "& div > div": {
        fontSize: 18,
        fontFamily: theme.fonts.poppins,
      },
    },
  },
  padding: props => ({
    padding: props.padding,
  }),
  rule: {
    borderTop: `1px solid ${theme.colors.darks.lightGreyInt}`,
  },
  robotTop: {
    position: "absolute",
    right: "-15px",
    top: "-178px",
  },
  robotRight: {
    position: "absolute",
    bottom: 0,
    right: "-115px",
    zIndex: 4,
  },
  arrowRight: {
    position: "absolute",
    right: "-90px",
    bottom: 80,
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& span": {
      fontFamily: theme.fonts.axiforma,
      color: theme.colors.lights.white,
      fontSize: 24,
      zIndex: 15,
    },

    "& div": {
      height: 70,
      width: 70,
      right: "-10px",
      backgroundColor: "#6D4495",
      transform: "rotate(45deg)",
      position: "absolute",
    },
  },
  "@media (min-width: 1281px)": {
    pillar: props => ({
      "&:not(:last-of-type)": {
        marginRight: props.pillarSide.width,
      },
    }),
  },
  "@media (max-width: 1280px)": {
    pillar: {
      // Specificity hack to override the prop classes above
      "&&": {
        width: "auto",
        height: "auto",
        marginTop: 35,
        paddingTop: theme.spacing.md,

        "&:after": {
          width: "auto",
          height: 0,
          top: 35,
          left: 0,
          position: "relative",
          transform: "unset",
          borderTop: "35px solid #6D4495",
          borderRight: "12px solid transparent",
          borderLeft: "12px solid transparent",
          backgroundColor: "transparent",
        },
      },

      "&:nth-child(1)": {
        width: "100%",
      },
      "&:nth-child(2)": {
        marginLeft: "11px",
        marginRight: "11px",
        width: "calc(100% - 22px)",
      },
      "&:nth-child(3)": {
        marginLeft: "22px",
        marginRight: "22px",
        paddingBottom: theme.spacing.md,
        marginBottom: 100,
        width: "calc(100% - 44px)",
      },
      "&:nth-child(3):after": {
        display: "none",
      },
    },
    title: {
      padding: "0 !important",
    },
    robotRight: {
      bottom: "-200px",
      zIndex: "-10",
    },
    arrowRight: {
      position: "relative",
      margin: "0 auto",
      right: "unset",
      bottom: "unset",
      left: "unset",
      top: 80,

      "& span": {
        fontSize: 18,
      },

      "& div": {
        height: 50,
        width: 50,
        right: "unset",
      },
    },
  },
  // Keep the robot at the bottom as things move around
  "@media (max-width: 632px)": {
    robotRight: {
      bottom: "-180px",
    },
  },
}))

function Pillar(props) {
  const { title, bottom, robotTop, robotRight, arrowRight, className } = props
  const cx = useStyles({ ...props })

  const classes = classNames(cx.pillar, className)

  return (
    <div className={classes}>
      {robotTop && (
        <StaticImage
          className={`${cx.robotTop} hidden--pc-down`}
          alt=""
          src="../../../../images/content-hub/sit_click_fraud_robot.png"
        />
      )}

      <div className="mx-md">
        <div className={`h3 ${cx.title}`}>{title}</div>

        <hr className={`my-md ${cx.rule}`} />

        <div className={`h3 ${cx.bottom}`}>{bottom}</div>
        {arrowRight && (
          <div className={cx.arrowRight}>
            <div />
            <span>{arrowRight}</span>
          </div>
        )}
      </div>

      {robotRight && (
        <StaticImage
          className={cx.robotRight}
          alt=""
          src="../../../../images/content-hub/click_fraud_robot_head.png"
        />
      )}
    </div>
  )
}

Pillar.propTypes = {
  // Disable because its being used by styling which prop-types can't detect
  /* eslint-disable react/no-unused-prop-types */
  /**
   * Width of the pillar
   */
  width: PropTypes.number,
  /**
   * Height of the pillar
   */
  height: PropTypes.number.isRequired,
  /**
   * Side and positioning for the side of the pillar
   */
  pillarSide: PropTypes.object,
  /**
   * Padding for the header
   */
  padding: PropTypes.string,
  /**
   * Title text for the pillar
   */
  title: PropTypes.string,
  /**
   * Bottom text for the pillar
   */
  bottom: PropTypes.node,
  /**
   * Whether this pillar contains a sitting robot
   */
  robotTop: PropTypes.bool,
  /**
   * Whether this pillar contains a robot to its right
   */
  robotRight: PropTypes.bool,
  /**
   * Content for an arrow to be displayed on the pillar
   */
  arrowRight: PropTypes.string,
  /**
   * Extra classes for the pillar
   */
  className: PropTypes.string,
}

Pillar.defaultProps = {
  width: 330,
  pillarSide: {
    width: 80,
    top: 22,
  },
  padding: "2rem 0 0",
  title: null,
  bottom: null,
  robotTop: false,
  robotRight: false,
  arrowRight: null,
  className: null,
}

Pillar.Wrapper = Wrapper

export default Pillar
