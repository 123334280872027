import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"

function RunicAccent({ side, size, dark, style, className }) {
  const classes = classNames(
    "absolute",
    "accent__runic",
    `accent__runic--${side}`,
    `accent__runic--${size}`,
    className,
  )

  if (dark) {
    return (
      <StaticImage
        src="../../images/accents/runic-dark.png"
        className={classes}
        placeholder="blurred"
        alt=""
        style={style}
      />
    )
  }
  return (
    <StaticImage
      src="../../images/accents/runic-light.png"
      className={classes}
      placeholder="blurred"
      alt=""
      style={style}
    />
  )
}

RunicAccent.defaultProps = {
  side: "left",
  size: "md",
  dark: false,
  style: null,
}

RunicAccent.propTypes = {
  side: PropTypes.oneOf(["left", "right"]).isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
  style: PropTypes.object,
  dark: PropTypes.bool.isRequired,
}

export default RunicAccent
