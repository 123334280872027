import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  lineHorizontal: {
    width: 52,
    border: 'white 2px solid',
    transform: 'translate(472px, -250px)',
  },
  lineSlanted: {
    position: 'absolute',
    transformOrigin: 'left',
    border: 'white 2px solid',
    width: 276,
    transform: 'translate(525px, -252px) rotate(-77deg)',
  },
});

function GlobeLines() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.lineHorizontal} />
      <div className={classes.lineSlanted} />
    </>
  );
}

export default GlobeLines;
