import React from 'react';
import PropTypes from 'prop-types';
import SectionContent from '@components/containers/section-content';
import Section from '@components/containers/section';
import Heading from '@components/content/heading';
import Button from '@components/interactive/button';
import { createUseStyles } from 'react-jss';

import parse from 'html-react-parser';
import background from '../../../images/content-hub/analyse_multiplatform_cta.png';
import background2 from '../../../images/content-hub/graph.png';

const useStyles = createUseStyles((theme) => ({
  section: (props) => ({
    minHeight: 680,
    background: `linear-gradient(180deg, ${theme.colors.core.core}, ${theme.colors.core.coreDark} 50%)`,

    '&:before': {
      content: '""',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '110%',
      display: 'block',
      backgroundImage: `url("${props.alternateBackground ? background2 : background}")`,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  }),
  content: {
    maxWidth: 590,
  },
  '@media (max-width: 1500px)': {
    section: {
      '&:before': {
        backgroundPosition: 'right',
        opacity: 0.1,
      },
    },
  },
}));

function CTA(props) {
  const {
    heading, content, linkText, linkHref, newTab,
  } = props;
  const cx = useStyles({ ...props });

  return (
    <Section theme="dark" className={cx.section}>
      <SectionContent paddingTop="lg" paddingBot="lg">
        <div className={cx.content}>
          <Heading level="2" className="mb-md">
            {parse(heading)}
          </Heading>
          <p className="mb-md">
            {content}
          </p>
          <Button color="yellow-black" href={linkHref} openTab={newTab}>
            {linkText}
          </Button>
        </div>
      </SectionContent>
    </Section>
  );
}

CTA.propTypes = {
  /**
   * Header text
   */
  heading: PropTypes.string.isRequired,
  /**
   * Content text
   */
  content: PropTypes.string.isRequired,
  /**
   * Button text
   */
  linkText: PropTypes.string.isRequired,
  /**
   * Button link
   */
  linkHref: PropTypes.string.isRequired,
  /**
   * Use the alternate background image
   */
  // Used by styling which ESLint can't see
  // eslint-disable-next-line react/no-unused-prop-types
  alternateBackground: PropTypes.bool,
};

CTA.defaultProps = {
  alternateBackground: false,
};

export default CTA;
