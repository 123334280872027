import React from 'react';
import { createUseStyles } from 'react-jss';
import parse from 'html-react-parser';
import Section from '../../../components/containers/section';
import SectionContent from '../../../components/containers/section-content';
import { Rows, Columns } from '../../../components/grid';
import Button from '../../../components/interactive/button';
import Image from './Image';
import PropTypes from 'prop-types';

const useStyles = createUseStyles({
  blockquote: {
    position: 'relative',
  },
  quotemark: {
    position: 'absolute',
    fontSize: 65,
    left: -48,
    top: -20,
  },
  rightColumn: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  // Intentionally empty so we can use it for mobile styling
  leftColumn: {},

  '@media (min-width: 1300px)': {
    rightColumn: {
      justifyContent: 'center',
    },
  },

  '@media (max-width: 768px)': {
    rightColumn: {
      order: 1,
      display: 'block',
    },
    leftColumn: {
      order: 2,
    },
    quotemark: {
      display: 'none',
    },
  },
});

function Quote({ data }) {
  const cx = useStyles();
  const {
    buttonText, buttonURL, company, headshot, quote, quoteName,
  } = data;

  return (
    <Section theme="light">
      <SectionContent paddingTop="sm" paddingBot="sm">

        <Rows>
          <Columns count="2,1">

            <div className={`tab-px-sm tab-pl-lg ${cx.leftColumn}`}>
              <blockquote className={`h2 pb-md ${cx.blockquote}`}>
                <span className={`${cx.quotemark} text-gradient text-gradient__pink-red-270`}>
                  &quot;
                </span>
                {parse(quote)}
              </blockquote>
              <Button
                color="coral"
                href={buttonURL}
              >
                {buttonText}
              </Button>
            </div>

            <div className={cx.rightColumn}>
              <Image company={company} headshot={headshot} name={quoteName} />
            </div>
          </Columns>
        </Rows>

      </SectionContent>
    </Section>
  );
}

Quote.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
};

export default Quote;
