import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  imageWrapper: {
    backgroundColor: theme.colors.core.core,
    display: "inline-block",
    borderRadius: "50%",
    position: "relative",
    overflow: "hidden",
    height: 230,
    width: 230,
  },
  bar: {
    backgroundColor: theme.colors.core.coreLight,
    color: theme.colors.lights.white,
    borderRadius: 20,
    zIndex: 2,
    textAlign: "right",
    width: 242,
    position: "absolute",
    left: 120,
    bottom: 50,
  },
  circle: {
    height: 200,
    width: 200,
    backgroundColor: theme.colors.lights.white,
    opacity: 0.1,
    left: -125,
    bottom: 0,
    borderRadius: "100%",
    position: "absolute",
  },
  circle2: {
    extend: "circle",
    left: 120,
    bottom: 50,
  },
  image: {
    zIndex: 3,
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  name: {
    fontSize: 19,
    lineHeight: "26px",
    fontFamily: theme.fonts.axiforma,
  },
  title: {
    fontSize: 16,
    lineHeight: "30px",
    opacity: 0.5,
  },
  bush: {
    position: "absolute",
    left: 80,
    bottom: 125,
    width: 250,
  },
  container: {
    position: "relative",
  },
  /**
   * Safari specific
   * Safari has a long-standing but where rounded corners and overflow hidden don't work.
   * The fix involves messing with the stacking context and sending the image to the bottom of Z
   * This means I can't put .bar under the image Z
   *
   * On Safari the .bar will display next to the image, and below on mobile.
   * ~ luke
   */
  "@media not all and (min-resolution:.001dpcm)": {
    "@supports (-webkit-appearance:none) and (stroke-color:transparent)": {
      imageWrapper: {
        "-webkit-mask-image":
          "-webkit-radial-gradient(white, black) !important",
      },

      bar: {
        left: "200px !important",
        width: "180px !important",
      },

      "@media (max-width: 768px)": {
        bar: {
          width: "100% !important",
          position: "relative",
          left: "0 !important",
          textAlign: "center",
          bottom: 15,
        },
      },
    },
  },
  "@media (max-width: 768px)": {
    imageWrapper: {
      width: 156,
      height: 156,
    },
    image: {
      maxWidth: 140,
      right: 5,
    },
    bar: {
      left: 80,
      bottom: 20,
    },
    bush: {
      width: 210,
      bottom: 90,
      left: 90,
    },
    container: {
      maxWidth: 350,
      paddingTop: 60,
      margin: "0 auto",
    },
  },
}))

function Image({ company, headshot, name }) {
  const cx = useStyles()

  return (
    <div className={cx.container}>
      <StaticImage
        className={cx.bush}
        alt=""
        src="../../../../images/content-hub/bush.png"
      />
      <div className={cx.imageWrapper}>
        <div className={cx.circle} />
        <div className={cx.circle2} />
        <div>
          <GatsbyImage
            image={headshot.localFile.childImageSharp.gatsbyImageData}
            alt={headshot.alternativeText}
            className={cx.image}
          />
        </div>
      </div>

      <div className={`${cx.bar} py-xs px-sm`}>
        <p className={cx.name}>{name}</p>
        <p className={`${cx.title} text--normal`}>{company}</p>
      </div>
    </div>
  )
}

export default Image
