import React from 'react';
import ImageSlider from '@components/interactive/ImageSlider';
import SectionContent from '@components/containers/section-content';
import CssAccentGroup from '@components/accents/css-accent-group';
import { createUseStyles } from 'react-jss';
import { StaticImage } from 'gatsby-plugin-image';
import rawTheme from '@src/theme';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import Section from '../../../components/containers/section';
import Heading from '../../../components/content/heading';

const useStyles = createUseStyles({
  outerContainer: {
    height: 1400,
  },
  '@media (max-width: 1000px)': {
    outerContainer: {
      height: 1300,
    },
  },
  '@media (max-width: 769px)': {
    outerContainer: {
      height: 'auto',
    },
  },
  content: {
    maxWidth: 885,
  },
  container: {
    position: 'relative',
  },
  bush: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  arrows: {
    position: 'absolute',
    top: '-45px',
    left: '-40px',
    zIndex: 1,
  },
});

function ImageDifference({ data }) {
  const cx = useStyles();
  const {
    content, heading, imageOne, imageTwo, preHeading,
  } = data;

  const accentData = [
    {
      size: 28,
      color: rawTheme.colors.accents.nebula,
      opacity: 0.8,
      left: 0,
      top: 30,
    },
    {
      size: 18,
      color: rawTheme.colors.accents.nebula,
      opacity: 0.3,
      left: '-25px',
      top: 35,
    },
  ];

  const bottomAccentData = [
    {
      size: 17,
      color: rawTheme.colors.accents.qwerty,
      opacity: 1,
      left: 0,
      top: 30,
    },
    {
      size: 14,
      color: rawTheme.colors.accents.qwerty,
      opacity: 0.3,
      left: 40,
      top: 30,
    },
    {
      size: 12,
      color: rawTheme.colors.accents.qwerty,
      opacity: 0.2,
      left: 60,
      top: 50,
    },
  ];

  return (
    <Section>
      <SectionContent className={cx.outerContainer} paddingTop="md" paddingBot="sm">
        <div className={cx.content}>
          <CssAccentGroup
            transform="translate(100%,-250%)"
            data={accentData}
            className="hidden--tab-down"
          />
          <Heading preheading={preHeading} className="mb-sm">
            {parse(heading)}
          </Heading>
          <p className="mb-sm">
            {parse(content)}
          </p>
        </div>

        <CssAccentGroup
          transform="translate(100%,-40%)"
          data={bottomAccentData}
          className="hidden--tab-down"
        />

        <div className={`${cx.container} hidden--tab-down`}>

          <StaticImage
            className={cx.arrows}
            alt=""
            src="../../../images/content-hub/Quiver_of_arrows.png"
          />
          <ImageSlider
            leftImage={imageOne.localFile.publicURL}
            rightImage={imageTwo.localFile.publicURL}
          />
          <StaticImage
            className={cx.bush}
            alt=""
            src="../../../images/content-hub/bush.png"
          />

        </div>
      </SectionContent>
    </Section>
  );
}

ImageDifference.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
};

export default ImageDifference;
