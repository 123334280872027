import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import Card from '../card';

const useStyles = createUseStyles((theme) => ({
  card: (props) => ({
    maxWidth: props.maxWidth,
    minHeight: 200,
    width: '100%',
    backgroundColor: theme.colors.lights.subtleWhite,
    position: 'relative',
    boxShadow: `0 0 25px ${theme.colors.darks.black}1A`,
  }),
  contentContainer: (props) => ({
    maxWidth: props.contentMaxWidth,
    marginLeft: props.rightImage ? 0 : 'auto',
  }),
  centerVertical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  number: {
    color: theme.colors.core.core,
  },
  heading: {
    '&&': {
      lineHeight: '30px',
    },
  },
  leftImage: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    borderBottomLeftRadius: theme.spacing.sm,
  },
  rightImage: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    borderBottomRightRadius: theme.spacing.sm,
  },
  content: (props) => ({
    zIndex: 2,
    textAlign: props.rightImage ? 'right' : 'left',
  }),
  '@media(max-width: 1280px)': {
    leftImage: {
      zIndex: 0,
      opacity: 0.35,
    },
    rightImage: {
      zIndex: 0,
      opacity: 0.35,
    },
  },
  '@media (max-width: 768px)': {
    rightImage: {
      right: 'unset',
      height: '110%',
      left: '-100px',
      borderBottomRightRadius: 'unset',
      transform: 'scaleX(-1)',
    },
    leftImage: {
      height: '110%',
      borderBottomLeftRadius: 'unset',
      left: '-100px',
    },
    card: {
      '&&': {
        width: '100%',
        maxWidth: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        paddingTop: theme.spacing.lg,
        paddingBottom: theme.spacing.lg,
      },
    },
    content: {
      '& h3': {
        fontSize: '24px',
      },

      '& p': {
        fontSize: '18px',
      },

      '&&': {
        textAlign: 'left',
      },
    },
    contentContainer: {
      '&&': {
        marginLeft: 'auto',
        maxWidth: 280,
      },
    },
  },
}));

function ImageCard(props) {
  const {
    number, heading, content, image, rightImage, centerVertical,
  } = props;
  const cx = useStyles({ ...props });

  const classes = classNames(
    cx.contentContainer,
    { [cx.centerVertical]: centerVertical },
  );

  return (
    <Card className={cx.card}>
      <div className={classes}>

        { !rightImage
            && (
            <div>
              <img
                className={cx.leftImage}
                alt=""
                src={image}
              />
            </div>
            )}

        <div className={cx.content}>
          <h3 className={`h4 ${cx.heading} mb-sm`}>
            <span className={cx.number}>
              {number}
              .
            </span>
            {' '}
            {heading}
          </h3>
          <p className="text--xs">
            {content}
          </p>
        </div>

        { rightImage
            && (
              <div>
                <img
                  className={cx.rightImage}
                  alt=""
                  src={image}
                />
              </div>
            )}
      </div>
    </Card>
  );
}

ImageCard.propTypes = {
  /**
   * Number to display
   */
  number: PropTypes.number.isRequired,
  /**
   * Header content
   */
  heading: PropTypes.string.isRequired,
  /**
   * Text content
   */
  content: PropTypes.string.isRequired,
  /**
   * Max width for the content
   */
  // Suppressed as it's used by styling
  // eslint-disable-next-line react/no-unused-prop-types
  contentMaxWidth: PropTypes.string,
  /**
   * Image to display
   */
  image: PropTypes.string.isRequired,
  /**
   * Put the image on the right
   */
  rightImage: PropTypes.bool,
  /**
   * The text content should be centered vertically
   */
  centerVertical: PropTypes.bool,
};

ImageCard.defaultProps = {
  rightImage: false,
  contentMaxWidth: '280px',
  centerVertical: false,
};

export default ImageCard;
